import React from "react"
import CardPartner from "../components/cardPartner"
import IntegrationCardsContainer from "../components/integrationCardsContainer"
import BackgroundShape from "../components/backgroundShape"
import Button from "../components/button"
import SubHeader from "../components/subHeader"
import Container from "../components/container"
import Layout from "../components/layout"
import SectionLayout from "../components/sectionLayout"
import Head from "../components/head"
import hero from "../assets/icons/hero.svg"
import { graphql } from "gatsby"



export const query = graphql`
  query($title: String!) {
    contentfulPlatformCategory(title: { eq: $title }) {
      title
      summary {
        summary
      }
      platform {
        title
        slug
        plans
        summary
      }
    }
    allContentfulPlatformCategory {
      edges {
        node {
          title
        }
      }
    }
  }
`
const PartnerLayout = (props) => {

   return (
            <Layout navbar="transparent">
			<Head title="Partners" description="Our cloud based software helps you connect with multiple vendors and manage all your ecommerce operations from a single platform"/>
				<BackgroundShape
					style={{ shape: "", color: "bg-partnersC-lg", particles: false }}
				/>
				<Container style={{ classes: "banner-container container" }}>
					<div className="d-flex justify-content-between align-items-center">
						<div className="header w-50">
							<h1 className="header__banner-title">Partner With Onport</h1>
							<p className="header__banner-subtitle">
								Here at Onport, we understand that in business, no company is an island. That is why we believe in the strength of collaboration when it comes to fueling innovation and helping to sculpt the marketplaces of the future.							</p>
							<a href="/contacts/partners" className="btn-home alt">
								Become a partner
							</a>
						</div>
						<div className="w-50 desktop-only text-center">
							<img src='https://imgur.com/aeQsEOr.png' style={{height:'400px'}} data-sal='slide-left' data-sal-duration='600'/>
						</div>
					</div>
				</Container>
				<Container style={{ classes: "container pb-5" }}>
					<div className="d-flex justify-content-between align-items-center pb-4">
						<SubHeader
							content={{
								title: "Establish Your Marketplace Solution Within Composable Commerce Infrastructure",
								text:
								"Together with Onport, you can ensure that your solution becomes an integrated component into the ever-growing tapestry of modern commerce. Unveil new opportunities for growth and enhance the value proposition to your customers.</br>The marketplace eco-space is full of intriguing and promising collaborative opportunities which offer true value to both parties and provide tangible results. Join our partnership program and see the results for yourself today.",
							}}
							style={{ color: "mt-5" }}
							/>
						<div className="w-25 desktop-only position-relative">
							<img
							src='https://assets.jetti.io/assets/illustrations/merge.svg'
							alt="home"
							data-sal="slide-left"
							data-sal-delay="300"
							data-sal-easing="ease"
							data-sal-duration="600"
							/>
						</div>
					</div>
				</Container>

			<Container style={{ classes: "container-fluid bg-partnersC-md pb-5" }}>
					<Container style={{ classes: "container pb-5" }}>
						<SubHeader
							content={{
								title: "Why You Should Partner With Onport",
								text: "Onport is well integrated into the marketplace eco-space, having formed robust relationships with many different platforms and solutions, all working together towards a common goal - to elevate the marketplace model to the highest possible level. Why not join our community today?",
							}}
							style={{ color: "text-white" }}
							/>
							<div className="row justify-content-around align-items-center">
								<div className="col-12 col-md-4">
									<img style={{ height: "64px", width: "64px", margin: "2rem auto", display: "block", }} src="https://taact-brand-assets.netlify.app/assets/icons/jetti/rocket-reverse.svg" data-sal="fade" data-sal-easing="ease" data-sal-duration="900" />
									<h2 style={{ fontSize: "1rem" }} className="text-white text-center" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="600" >GROWTH</h2>
									<p className="text-white text-center" style={{fontWeight: '300', fontSize:'.9rem'}}>Grow your business and increase the size of your product audience by aligning your solution with a leading online marketplace platform</p>
								</div>
								<div className="col-12 col-md-4">
									<img style={{ height: "64px", width: "64px", margin: "2rem auto", display: "block", }} src="https://taact-brand-assets.netlify.app/assets/icons/jetti/size-alt-reverse.svg" data-sal="fade" data-sal-easing="ease" data-sal-duration="900" />
									<h2 style={{ fontSize: "1rem" }} className="text-white text-center" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="600" >FLEXIBILITY</h2>
									<p className="text-white text-center" style={{fontWeight: '300', fontSize:'.9rem'}}>A flexible platform built on modern technology that’s easy to learn and work with.</p>
								</div>
								<div className="col-12 col-md-4">
									<img style={{ height: "64px", width: "64px", margin: "2rem auto", display: "block", }} src="https://taact-brand-assets.netlify.app/assets/icons/jetti/brand-reverse.svg" data-sal="fade" data-sal-easing="ease" data-sal-duration="900" />
									<h2 style={{ fontSize: "1rem" }} className="text-white text-center" data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="600" >OPPORTUNITY</h2>
									<p className="text-white text-center" style={{fontWeight: '300', fontSize:'.9rem'}}>Gain high-level opportunities by exploring a thriving and dynamic eco-space</p>
								</div>
							</div>
					</Container>
			</Container>
      <div id="filter">
      <SubHeader
					content={{
						title: "Partners",
						text: "Onport integrates seamlessly with the world's biggest ecommerce platforms, marketplaces, shipping couriers and accounting platforms.",
					}}
					style={{ color: "text-center mx-auto pb-0" }}
				/>
           <div className="integration-category-btn features-grid features-grid__center features-grid__8-col">
           <Button style={{ href:`/partners#filter`, text: "All", shape: "btn-integration", active:"btn-integration btn-integration__active"}}/>
                { props.data.allContentfulPlatformCategory.edges.sort((a, b) => b.node.title.localeCompare(a.node.title)).map((edge) => {
                      return(
                        <Button style={{ href:`/partners/${edge.node.title.toLowerCase()}#filter`, text: edge.node.title, shape: "btn-integration", active:""}}/>
                        )
                      }
                  )
                }
            </div>
                <div className="">
                  <IntegrationCardsContainer>
                    {props.data.contentfulPlatformCategory.platform.sort((a, b) => a.title.localeCompare(b.title)).map((edge) => {
                        return(
                          <CardPartner content={{
                            title: edge.title,
                            slug: edge.slug,
                            plans: edge.plans,
                            summary: edge.summary,
                            category: props.data.contentfulPlatformCategory.title.toLowerCase(),
                            image: require(`../assets/icons/platform/round/${edge.slug}.svg`)}} />
                        )})
                    }
                    </IntegrationCardsContainer>
					<Container style={{classes: "text-center pt-5 mb-5" }}>
						{ props.data.contentfulPlatformCategory.title.toLowerCase() === 'dropship' ? <div><p className="feature-highlight__title mb-4">Learn more about our integrations capabilities</p><a className='btn-contact position-static' href='https://onport.com//partners/vendor-status' target='_blank'>Learn more</a></div> : ''}
						{ props.data.contentfulPlatformCategory.title.toLowerCase() === 'store' ? <div><p className="feature-highlight__title mb-4">Learn more about our integrations capabilities</p><a className='btn-contact position-static' href='https://onport.com//partners/channel-status' target='_blank'>Learn more</a></div> : ''}
					</Container>
                  </div>
                  </div>
            </Layout>
    )
}


export default PartnerLayout
